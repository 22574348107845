/* IMPORTS */

// CSS import:
import './SocialMedia.scss';
import './SocialMediaQuery.scss';
// Icons import:
import { AiOutlineShop } from 'react-icons/ai';
import { RiYoutubeLine } from 'react-icons/ri';
import { FaInstagram } from 'react-icons/fa';
import { VscCallOutgoing } from 'react-icons/vsc';
import { CgArrowUpO } from "react-icons/cg";
import { Link } from 'react-scroll';


/* COMPONENTS */

// Social Media component:
const SocialMedia = ({ }) => (
    <div className="social-bar">
        <a className="icon icon-youtube" href="https://www.youtube.com/@soniagiustovideos" target="_blank">
            <RiYoutubeLine />
        </a>
        <a className="icon icon-instagram" href="https://www.instagram.com/soniagiustoart/" target="_blank">
            <FaInstagram />
        </a>
        <a className="icon icon-shop" href="https://www.soniagiustoart.com/" target="_blank">
            <AiOutlineShop />
        </a>
        <a className="icon icon-whatsapp" href="tel:+15612984503" target="_blank">
            <VscCallOutgoing />
        </a>
        <Link to='home' className='icon icon-up'> <CgArrowUpO /> </Link>
    </div>
);

export default SocialMedia;