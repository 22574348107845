/* IMPORTS */

// SCSS import:
import './VideoDrone.scss';
import './VideoDroneQuery.scss';
// Images import:
import Drone1 from '../../../Assets/Videos/Drone1.png';
import Drone2 from '../../../Assets/Videos/Drone2.png';
import Drone3 from '../../../Assets/Videos/Drone3.png';
import Drone4 from '../../../Assets/Videos/Drone4.png';
// Components import:
import DroneVideo from '../../../Assets/Videos/SoniDrone.mp4';
// React import:
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';


/* COMPONENTS */

// VideoDrone component:
const VideoDrone = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <Container className='VideoDroneContainer'>
            <video autoPlay loop muted className='VideoDrone'><source src={DroneVideo} type='video/mp4'></source></video>
            <div className='VideoDroneText'>
                <p className='DroneTitle'>{t('drone.title')}</p>
                <p className='DroneText'>{t('drone.text')}</p>
            </div>

            <section>
                <Carousel slide={false}>
                <Carousel.Item className='CarouselItem'>
                        <img
                        className="d-block w-100 Carousel__heroImage"
                        src={Drone1}
                        alt="First slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item className='CarouselItem'>
                        <img
                        className="d-block w-100 Carousel__heroImage"
                        src={Drone2}
                        alt="First slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item className='CarouselItem'>
                        <img
                        className="d-block w-100 Carousel__heroImage"
                        src={Drone3}
                        alt="First slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item className='CarouselItem'>
                        <img
                        className="d-block w-100 Carousel__heroImage"
                        src={Drone4}
                        alt="First slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </section>
        </Container>
    )
}

export default VideoDrone;