/* IMPORTS */

// SCSS import:
import './Footer.scss';
import './FooterQuery.scss';
// Components import:
// React import:
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';



/* COMPONENTS */

// Footer component:
const Footer = () => {
    const [t] = useTranslation("global");

    return (
        <Container fluid className='FooterContainer' id='footer'>
            <Row className='FooterRow'>
                <Col className='FooterText'>
                    <div className='FooterTextContent'>
                        <div className='FooterLeft'>
                            <p className='Title'> Sonia Giusto </p>
                            <p className='CopyrightSmallDevices'> &copy; 2023 Sonia Giusto. {t('footer.copyright')} <br></br><a className='rm' href="https://api.whatsapp.com/send?phone=5493516327518&text=Hola!%20Me%20gustaría%20realizar%20una%20consulta."> {t('footer.rm')} </a> </p>
                        </div>
                    </div>
                </Col>
                <Col className='FooterNavbar'>
                    <ul>
                        <li> <Link to='home' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.home')} </Link> </li>
                        <li> <Link to='about' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.about')} </Link> </li>
                        <li> <Link to='recently' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.recently')} </Link> </li>
                        <li> <Link to='portfolio' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.portfolio')} </Link> </li>
                    </ul>
                    <p className='Copyright'> &copy; 2023 Sonia Giusto Art. {t('footer.copyright')} <br></br><a className='rm' href="https://api.whatsapp.com/send?phone=5493516327518&text=Hola!%20Me%20gustaría%20realizar%20una%20consulta."> {t('footer.rm')} </a> </p>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer;