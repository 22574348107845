/* IMPORTS */

// CSS import:
import './App.css';
// Components import:
import Home from './Components/Sections/Home/Home';
import AboutMe from './Components/Sections/AboutMe/AboutMe';
import RecentlyImages from './Components/Sections/RecentlyImages/RecentlyImages';
import VideoOcean from './Components/Sections/VideoOcean/VideoOcean';
import Portfolio from './Components/Sections/Portfolio/Portfolio';
import SliderDetails from './Components/Sections/SliderDetails/SliderDetails';
import VideoDrone from './Components/Sections/VideoDrone/VideoDrone';
import SocialMedia from './Components/SocialMedia/SocialMedia';
import Footer from './Components/Sections/Footer/Footer';
// React import:
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Helmet } from 'react-helmet';


/* COMPONENTS */

// App component:
const App = () => {

    return (
      <section className='App'>
        <header>
          <section className='Home' id='home'>
            <Home />
          </section>
        </header>

        <main>
          <AboutMe />
          <RecentlyImages />
          <VideoOcean />
          <Portfolio />
          <SliderDetails />
          <VideoDrone />
        </main>

        <footer>
          <section className='Footer'>
            <Footer />
          </section>
          <section className='SocialMedia'>
            <SocialMedia />
          </section>
        </footer>
      </section>
    )
}

export default App;