/* IMPORTS */

// SCSS import:
import './VideoOcean.scss';
import './VideoOceanQuery.scss';
// Images import:
import Ocean1 from '../../../Assets/Videos/OceanImg.jpg';
import Ocean2 from '../../../Assets/Videos/Ocean1.png';
import Ocean3 from '../../../Assets/Videos/Ocean2.png';
import Ocean4 from '../../../Assets/Videos/Ocean3.png';
// Components import:
import OceanVideo from '../../../Assets/Videos/SoniOceano.mp4';
// React import:
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';


/* COMPONENTS */

// VideoOcean component:
const VideoOcean = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <Container className='VideoOceanContainer'>
            <div className='VideoText'>
                <p className='OceanTitle'>{t('ocean.title')}</p>
                <p className='OceanText'>{t('ocean.text')}</p>
            </div>
            <video autoPlay loop muted className='VideoOcean'><source src={OceanVideo} type='video/mp4'></source></video>

            <section className='OceanPictures'>
                <Carousel slide={false}>
                <Carousel.Item className='CarouselItem'>
                        <img
                        className="d-block w-100 Carousel__heroImage"
                        src={Ocean1}
                        alt="First slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item className='CarouselItem'>
                        <img
                        className="d-block w-100 Carousel__heroImage"
                        src={Ocean2}
                        alt="First slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item className='CarouselItem'>
                        <img
                        className="d-block w-100 Carousel__heroImage"
                        src={Ocean3}
                        alt="First slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item className='CarouselItem'>
                        <img
                        className="d-block w-100 Carousel__heroImage"
                        src={Ocean4}
                        alt="First slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </section>
        </Container>
    )
}

export default VideoOcean;