/* IMPORTS */

// CSS import:
import './SliderDetails.scss';
import './SliderDetailsQuery.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// React import:
import React, { Component } from "react";
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';


/* COMPONENTS */

// SliderDetails component:
const SliderDetails = () => {
    const [t, i18n] = useTranslation("global");

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


        return (
        <div className='SliderContainer'>
            <h3 className='DetailsTitle'> {t('details.title')} </h3>
            <hr className='Line'></hr>
            <Slider {...settings} className='Slider'>
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image detail1'>
                            <div className='CardItem__description'>
                                <p> Casanova </p>
                            </div >
                        </div>
                    </div>
                </div >
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image detail2'>
                            <div className='CardItem__description'>
                                <p> Limones </p>
                            </div >
                        </div>
                    </div>
                </div >
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image detail3'>
                            <div className='CardItem__description'>
                                <p> Rafaela </p>
                            </div >
                        </div>
                    </div>
                </div >
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image detail4'>
                            <div className='CardItem__description'>
                                <p> Multifloral </p>
                            </div >
                        </div>
                    </div>
                </div >

                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image detail5'>
                            <div className='CardItem__description'>
                                <p> Casanova </p>
                            </div >
                        </div>
                    </div>
                </div >
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image detail6'>
                            <div className='CardItem__description'>
                                <p> Rosemary </p>
                            </div >
                        </div>
                    </div>
                </div>
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image detail7'>
                            <div className='CardItem__description'>
                                <p> Tropical Pineapples </p>
                            </div >
                        </div>
                    </div>
                </div>
                <div className='CardItem__container'>
                    <div className='CardItem__content'>
                        <div className='CardItem__image detail8'>
                            <div className='CardItem__description'>
                                <p> Casanova </p>
                            </div >
                        </div>
                    </div>
                </div>

            </Slider>
        </div>
        );
    }

export default SliderDetails;