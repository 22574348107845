/* IMPORTS */

// SCSS import:
import './Home.scss'
import './HomeQuery.scss';
// Components import:
import NavBar from './NavBar/NavBar';
// React import:
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


/* COMPONENTS */

// Home component:
const Home = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <Container fluid className='HomeContainer' id='home'>
            <div className='DivHome'>
                <Row className='HomeNavBarRow'>
                    <NavBar />
                </Row>
                <Row className='HomeRow'>
                    <div className='DivContent'>
                        <p className='Welcome'> {t('home.welcome')} </p>
                        <h1>Sonia Giusto</h1>
                    </div>
                </Row>
            </div>
            <div className='HeroImg'></div>
        </Container>
    );
};

export default Home;