/* IMPORTS */

// SCSS import:
import './AboutMe.scss';
import './AboutMeQuery.scss';
// Components import:
import FadeSlider from '../../FadeSlider/FadeSlider';
import { AboutMeImages } from '../../../Assets/Assets';
import CurriculumIngles from '../../../Assets/Curriculum/CurriculumIngles.pdf';
import CurriculumEspanol from '../../../Assets/Curriculum/CurriculumEspañol.pdf';
// React import:
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { useTranslation } from 'react-i18next';
import { AiTwotoneShop } from 'react-icons/ai';
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneOutboundFill } from 'react-icons/bs';


/* COMPONENTS */

// AboutMe component:
const AboutMe = () => {
    const [t] = useTranslation("global");

    return (
        <Container fluid className='AboutMeContainer' id='about'>
            <Row className='AboutMeRow'>

                <Col xl={6} lg={6} md={6} sm={12} className='AboutMeText'>
                    <div className='AboutMeTextContent'>
                        <h3> {t('about.title')} <br/></h3>
                        <div className='LineContainer'>
                            <hr className='Line'></hr>
                        </div>
                        <div className='LargeDevicesContainer'>
                            <p className='LargeDevices'> {t('about.text-large-devices')} </p>
                        </div>
                        <ul className='Info'>
                            <li><BsFillTelephoneOutboundFill size={16}/> <a className="icon icon-whatsapp" href="tel:+15612984503" target="_blank">+1 561 - 2984503</a></li>
                            <li><AiTwotoneShop size={20}/> <a className="icon icon-shop" href="https://www.soniagiustoart.com/" target="_blank"> soniagiustoart.com </a></li>
                            <li><MdEmail size={20}/> soniagiusto25@gmail.com</li>
                        </ul>
                        <div className='ButtonsCV'>
                            <a href={CurriculumIngles} download>
                                <button className='buttonCV English'> {t('about.englishCV')} </button>
                            </a>
                            <a href={CurriculumEspanol} download>
                                <button className='buttonCV'>{t('about.spanishCV')}</button>
                            </a>
                        </div>
                    </div>
                </Col>
                <Col className='FadeSliderContainer'>
                    <FadeSlider images={AboutMeImages}/>
                </Col>
            </Row>

            <Row className='SkillsRow'>
                <Col className='Skill'>
                    <p> Oil Painting </p>
                    <div className='LineContainer'>
                        <hr className='LineSkill'></hr>
                    </div>
                    <div className='ImgSkillContainer'>
                        <div className='ImgOil'></div>
                    </div>
                </Col>
                <Col className='Skill'>
                    <p> Acrylic Painting </p>
                    <div className='LineContainer'>
                        <hr className='LineSkill'></hr>
                    </div>
                    <div className='ImgSkillContainer'>
                        <div className='ImgAcrylic'></div>
                    </div>
                </Col>
                <Col className='Skill'>
                    <p> Glass Painting </p>
                    <div className='LineContainer'>
                        <hr className='LineSkill'></hr>
                    </div>
                    <div className='ImgSkillContainer'>
                        <div className='ImgGlass'></div>
                    </div>
                </Col>
                <Col className='Skill'>
                    <p> Pencil & Ink Painting </p>
                    <div className='LineContainer'>
                        <hr className='LineSkill'></hr>
                    </div>
                    <div className='ImgSkillContainer'>
                        <div className='ImgPencil'></div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AboutMe;