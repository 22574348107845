/* IMPORTS */

// SCSS import:
import './NavBar.scss'
import './NavBarQuery.scss';
// Components import:
import BurgerMenu from '../../../BurgerMenu/BurgerMenu';
// React import:
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';


/* COMPONENTS */

// NavBar component:
const NavBar = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <Container fluid className='NavBarContainer' id='home'>
            <Row className='NavBarRow'>
                <Col xl={4} lg={2} md={1} sm={1} className='NavBarLogo'>
                    <ul>
                        <li> <Link to='home' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.logo')} </Link> </li>
                    </ul>
                </Col>
                <Col className='NavBarLinks'>
                    <ul>
                        <li> <Link to='home' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.home')} </Link> </li>
                        <li> <Link to='about' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.about')} </Link> </li>
                        <li> <Link to='recently' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.recently')} </Link> </li>
                        <li> <Link to='portfolio' spy={true} smooth={true} offset={0} duration={600} className='NavBarLink'> {t('nav-bar.portfolio')} </Link> </li>
                        <li>
                            <button className='ButtonLink' onClick={() => i18n.changeLanguage('en')}> {t('nav-bar.language-eng')} </button>
                            |
                            <button className='ButtonLink' onClick={() => i18n.changeLanguage('es')}> {t('nav-bar.language-spa')} </button>
                        </li>
                    </ul>
                </Col>
                <label className='BurgerMenuLabel'> <BurgerMenu/> </label>
            </Row>
        </Container>
    );
};

export default NavBar;